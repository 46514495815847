import Image from "components/Image"
import richText from "utilities/richText"
import cn from "classnames"

export default function ImageAndContent({ blok }) {
  const section = cn("py-12", blok.background_color && `bg-${blok.background_color}`)

  const container = cn(
    "image-content-container flex flex-col gap-8 xl:gap-12 justify-between items-center lg:items-start mx-auto max-w-screen-xl",
    {
      "lg:flex-row-reverse justify-between": blok.orientation === "content_first",
      "lg:flex-row": blok.orientation === "image_first",
      "text-white": blok.background_color === "gray-dark",
    }
  )

  return (
    <section className={section}>
      <div className="px-8">
        <div className={container}>
          <div className={"image-container max-w-lg lg:max-w-none lg:w-full basis-1/3 relative md:pt-2 md:pb-12"}>
            <Image
              src={blok.image.filename}
              alt={blok.image.alt}
              placeholder={blok.image?.blurDataURL ? "blur" : "empty"}
              blurDataURL={blok.image?.blurDataURL}
              width={397}
              height={370}
            />
          </div>
          <div className="basis-2/3 image-content">
            <h2 className="pb-4 text-l1 lg:text-l2">{blok.heading}</h2>
            <div className="content prose-h3:pt-4 prose-p:py-2 prose-h3:text-s3 prose-h3:uppercase prose-h2:py-4 prose-h2:text-l1 prose-h2:lg:text-l2">
              {richText(blok.content)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
